<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> Transportation Importer</b-card-title>
			<b-card-sub-title>Imports transportation data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- Transportation Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="Transportation Form"
										description="Please select a valid json or csv file for transportation import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<div>
										Download JSON template
										<a :href="jsonTemplateUrl" download="TransportationImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="TransportationImporter.csv">
											here.
										</a>
									</div>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the transportation import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
	name: 'transportation-importer',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			file: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonData: null,
			jsonTemplateUrl: '../../assets/files/TransportationImporter.json',
			csvTemplateUrl: '../../assets/files/TransportationImporter.csv',

			allCompaniesObj: {},
			allTransportationsObj: {},
			currUserId: this.$store.getters.loggedUser.id,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
		plateNoRegex() {
			return config.plateNoRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer 
					|| this.$store.getters.isScanner
					|| this.$store.getters.isAccounting
					|| this.$store.getters.isApprover
					|| this.$store.getters.isMaintenance) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allTransportationsObj = { ...this.$store.getters.transportations };

			} catch(error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});

						this.jsonData = this.formatJsonData(data);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.transportations);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid Transportation Import Form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid Transportation Import Form in JSON or CSV format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);
				results.push(param);
			});

			return {
				transportations: results,
			};
		},

		async onImportData() {
			try {
				let transportationArr = this.jsonData.transportations;
				if (transportationArr.length === 0) {
					this.$toaster.warning('At least 1 transportation is required per import.');
					return;
				} else if (transportationArr.length > 5000) {
					this.$toaster.warning('Only maximum of 5,000 transportations is allowed per import.');
					return;
				} else if (ImportUtil.hasDuplicates(transportationArr, 'plateNo')) {
					let duplicates = ImportUtil.getDuplicates(
						transportationArr,
						'plateNo'
					);
					this.$toaster.warning(
						'Duplicates detected! Please address the following transportation(s): ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (ImportUtil.hasBlankColumnName(transportationArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(transportationArr)) {
					return;
				}

				await this.saveOnDatabase(this.jsonData);
			} catch (_error) {
				this.$toaster.warning('The imported transportation data is invalid and cannot be processed');
			}
		},
		validateDataImport(transportations) {
			let isValid = true;
			let errors = [];

			let companies = Object.values({ ...this.allCompaniesObj }).map(company => company.name);

			_.forEach(transportations, (item, key) => {
				let plateNo = item.plateNo;
				let index = key + 1;
				let errPrefix = (!plateNo || plateNo.length === 0) ? 'Item ' + index : 'Vehicle ' + plateNo;

				if (!plateNo || plateNo.length === 0) {
					errors.push(errPrefix + ' has no plate no. This is required.');
				} else if ((plateNo && plateNo.length !== 0)
					&& ValidationUtil.objectHasField('plateNo', plateNo, this.allTransportationsObj)) {
					errors.push('Vehicle ' + plateNo + ' already exists.');
				}

				let regex = new RegExp(this.plateNoRegex);
				if (!regex.test(plateNo)) {
					errors.push(errPrefix + ' has an invalid plate no. Valid format: ' + this.plateNoRegex);
				}

				if (!item.description || item.description.length === 0) {
					errors.push(errPrefix + ' has no description. This is required.');
				} else if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
					errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
				}

				if (!item.company || item.company.length === 0) {
					errors.push(errPrefix + ' has no company. This is required.');
				} else if (item.company && item.company.length !== 0 && !companies.includes(item.company)) {
					errors.push(errPrefix + ' is associated to company, "' + item.company + '", that you don\'t have access to.');
				}
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},
		async saveOnDatabase(json) {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					transportations: json.transportations,
				};

				let { data } = await dataImporterApi.importTransportations(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let transportationsObj = data.transportations;
				this.$store.dispatch('updateAllTransportations', transportationsObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}
	},
};
</script>